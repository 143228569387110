import React from "react"
import Layout from "../components/layout"
import { LanguageContext } from "../locales/langContext"
import Search from "../components/search"
import PageHeader from "../components/pageHeader"
import { getSearchPath } from "../components/pathUtils"

const SearchPage = ({ pageContext, location }) => {
  const locale = pageContext.locale
  const i18nPaths = pageContext.locales
    .filter(locale => locale !== "fr")
    .map(locale => {
      return {
        locale: locale,
        value: getSearchPath(locale),
      }
    })
  return (
    <Layout
      lang={locale}
      //   title={page.title}
      location={location}
      i18nPaths={i18nPaths}
      //   allSlugLocales={page.allSlugLocales}
    >
      <LanguageContext.Consumer>
        {t => <PageHeader title={t.search} simple />}
      </LanguageContext.Consumer>
      <Search locale={locale} />
    </Layout>
  )
}

export default SearchPage
